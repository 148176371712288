export const operationData = [
  {
    symbol: "AAPL",
    name: "Apple Inc.",
    quantity: "1100",
    price: "193.00",
    order_type: "buy",
    total: "212,300.00",
    txnId: "1234567890",
  },
  {
    symbol: "AAPL",
    name: "Apple Inc.",
    quantity: "1100",
    price: "193.00",
    order_type: "sell",
    total: "212,300.00",
    txnId: "1234567890",
  },
  {
    symbol: "AAPL",
    name: "Apple Inc.",
    quantity: "1100",
    price: "193.00",
    order_type: "executed",
    total: "212,300.00",
    txnId: "1234567890",
    approvalId: "1234567890",
    databaseId: "1234567890",
    transactionId: "124567890",
    url: "abcd",
  },
];

export const blockchainImageConfig: { [key: string]: string } = {
  Polygon: "/media/images/polygon-matic-logo.svg",
  Avalanche: "/media/images/avalanche-avax-logo.svg",
  Ethereum: "/media/images/ethereum-eth-logo.svg",
};