import numeral from "numeral";

export const NumberWithCommas = (number: number) => {
  return Number(number).toLocaleString();
};

export const useFormatNumber = () => {
  const numberDecimal = (value: number | undefined) => {
    return Number.isInteger(value)
      ? numeral(value ?? 0).format("0,0.00")
      : value;
  };

  return { numberDecimal };
};

export const AddZeroInSingleNumbers = (integer: string) => {
  return integer.length === 1 ? `0${integer}` : integer;
};

export const formatNumberWithCommas = (value: number | string, decimal = 2) => {
  const strValue = typeof value === 'number' ? value.toString() : value;
  if (!strValue) return "";
  const cleanedValue = strValue.replace(/,/g, "");
  
  if (!isNaN(Number(cleanedValue))) {
    return Number(cleanedValue).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: decimal,
    });
  }
  return strValue; 
};


